<template>
  <svg class="fill-current h-6 w-6 mr-2 text-blue-500 dark:text-gray-200 mt-1" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path d="m87.467 358.4c2.133 2.133 4.267 2.133 8.533 2.133s6.4 0 8.533-2.133l64-64c4.267-4.267 4.267-10.667 0-14.934l-64-64c-4.267-4.267-10.667-4.267-14.934 0s-4.267 10.667 0 14.934l55.467 55.467-55.466 55.467c-4.267 6.4-4.267 12.799-2.133 17.066z"/>
    <path class="animate-pulse-full-quick" d="m202.667 341.333h106.667c6.4 0 10.667-4.267 10.667-10.667s-4.267-10.667-10.667-10.667h-106.667c-6.4 0-10.667 4.267-10.667 10.667s4.267 10.667 10.667 10.667z"/>
    <path d="m458.667 0h-405.334c-29.866 0-53.333 23.467-53.333 53.333v405.333c0 29.867 23.467 53.334 53.333 53.334h405.333c29.867 0 53.334-23.467 53.334-53.333v-405.334c0-29.866-23.467-53.333-53.333-53.333zm-320 42.667c6.4 0 10.667 4.267 10.667 10.667s-4.268 10.666-10.667 10.666c-6.4 0-10.667-4.267-10.667-10.667s4.267-10.666 10.667-10.666zm-42.667 0c6.4 0 10.667 4.267 10.667 10.667s-4.267 10.666-10.667 10.666-10.667-4.267-10.667-10.667 4.267-10.666 10.667-10.666zm-42.667 0c6.4 0 10.667 4.267 10.667 10.667s-4.267 10.666-10.667 10.666-10.667-4.267-10.667-10.667 4.268-10.666 10.667-10.666zm437.334 416c0 17.066-14.934 32-32 32h-405.334c-17.066 0-32-14.934-32-32v-352h469.333v352z"/>
  </svg>
</template>

<script>
export default {
  name: "SvgLogo"
}
</script>
