<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 511.996 511.996" style="enable-background:new 0 0 511.996 511.996;" xml:space="preserve">
<g>
	<path style="fill:#52798F;" d="M263.998,141.006v-17.008c0-4.422-3.578-8-8-8c-4.422,0-8,3.578-8,8v17.008
		c2.557-0.658,5.238-1.008,8-1.008C258.76,139.998,261.441,140.348,263.998,141.006z"/>
  <path style="fill:#5C546A;" d="M255.998,163.998h21.438h9.554c-2.894-11.245-11.747-20.098-22.992-22.992
		c-2.557-0.658-5.238-1.008-8-1.008c-2.762,0-5.443,0.35-8,1.008c-11.245,2.894-20.098,11.747-22.992,22.992h9.554H255.998z"/>
  <path style="fill:#00E389;" d="M380.279,259.998l-52.125-78.187c-7.437-11.156-19.875-17.812-33.281-17.812h-1.109l31.997,96
		H380.279z"/>
  <path style="fill:#00E389;" d="M277.748,166.529c-0.28-0.841-0.313-1.691-0.312-2.531h-21.438v96h52.904L277.748,166.529z"/>
  <path style="fill:#00BBBB;" d="M218.233,163.998h-1.109c-13.406,0-25.844,6.656-33.281,17.812l-52.125,78.187h54.518
		L218.233,163.998z"/>
  <path style="fill:#00BBBB;" d="M255.998,163.998H234.56c0,0.84-0.032,1.69-0.312,2.531l-31.154,93.469h52.904V163.998z"/>
  <path style="fill:#00BBBB;" d="M277.748,166.529l31.154,93.469h16.859l-31.997-96h-6.774h-9.554
		C277.436,164.838,277.468,165.688,277.748,166.529z"/>
  <path style="fill:#52798F;" d="M203.094,259.998l31.154-93.469c0.28-0.841,0.313-1.691,0.312-2.531h-9.554h-6.774l-31.998,96
		H203.094z"/>
  <path style="fill:#5C546A;" d="M127.998,347.998c-0.695,0-1.391-0.094-2.063-0.273l-2.466-0.657l33.583,40.29
		c4.57,5.492,11.297,8.641,18.438,8.641h80.508v-48H127.998z"/>
  <path style="fill:#52798F;" d="M383.998,347.998h-128v48h80.508c7.141,0,13.867-3.148,18.438-8.633l33.583-40.298l-2.466,0.658
		C385.389,347.904,384.693,347.998,383.998,347.998z"/>
  <path style="fill:#E3E6E9;" d="M511.224,304.568c-0.867-1.82-22.195-44.57-111.227-44.57h-19.719h-54.518h-16.859h-52.904v16v32
		h255.989c0,1.146-0.237,2.294-0.731,3.359C512.256,309.201,512.24,306.709,511.224,304.568z"/>
  <path style="fill:#B6B8BE;" d="M255.998,307.998v-32v-16h-52.904h-16.859h-54.518h-19.719c-89.031,0-110.359,42.75-111.227,44.57
		c-1.016,2.141-1.031,4.633-0.031,6.789c-0.494-1.065-0.731-2.213-0.731-3.359H255.998z"/>
  <path style="fill:#888693;" d="M127.998,347.998h128v-16v-24H0.009c0,1.146,0.237,2.294,0.731,3.359
		c0.992,2.156,2.898,3.758,5.195,4.367l117.534,31.343l2.466,0.657C126.608,347.904,127.303,347.998,127.998,347.998z"/>
  <path style="fill:#B6B8BE;" d="M255.998,307.998v24v16h128c0.695,0,1.391-0.094,2.063-0.273l2.466-0.658l117.534-31.342
		c2.297-0.609,4.203-2.211,5.195-4.367c0.494-1.065,0.731-2.213,0.731-3.359H255.998z"/>
</g>
</svg>

</template>

<script>
export default {
name: "SvgUFO",
}
</script>

<style scoped>

</style>