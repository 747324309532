<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       viewBox="193.73118279569962 102.87096774193549 143.78494623655928 145.93548387096774">
    <defs>
      <path
          class="ping"
          d="M334.52 174.84C334.52 214.01 303.2 245.81 264.62 245.81C226.05 245.81 194.73 214.01 194.73 174.84C194.73 135.67 226.05 103.87 264.62 103.87C303.2 103.87 334.52 135.67 334.52 174.84Z"
          id="e3RLvoflfL1"></path>
      <path
          class="ping"
          d="M334.52 174.84C334.52 214.01 303.2 245.81 264.62 245.81C226.05 245.81 194.73 214.01 194.73 174.84C194.73 135.67 226.05 103.87 264.62 103.87C303.2 103.87 334.52 135.67 334.52 174.84Z"
          id="e3RLvoflfL2"></path>
      <path
          d="M234.25 129.14C234.25 132.11 231.36 134.52 227.8 134.52C224.23 134.52 221.34 132.11 221.34 129.14C221.34 126.17 224.23 123.76 227.8 123.76C231.36 123.76 234.25 126.17 234.25 129.14Z"
          id="e7IUXBSURh"></path>
      <path
          d="M241.24 196.4C241.24 201.89 236.78 206.34 231.29 206.34C225.8 206.34 221.34 201.89 221.34 196.4C221.34 190.91 225.8 186.45 231.29 186.45C236.78 186.45 241.24 190.91 241.24 196.4Z"
          id="f1nvNLVapS"></path>
      <path
          d="M294.84 132.2C294.84 137.69 290.38 142.15 284.89 142.15C279.4 142.15 274.95 137.69 274.95 132.2C274.95 126.71 279.4 122.26 284.89 122.26C290.38 122.26 294.84 126.71 294.84 132.2Z"
          id="a2oLNBRUjU"></path>
      <path
          d="M307.59 196.82C307.48 208.18 298.06 217.3 286.57 217.19C275.08 217.07 265.84 207.76 265.95 196.4C266.07 185.05 275.49 175.92 286.98 176.04C298.47 176.15 307.71 185.47 307.59 196.82Z"
          id="e3FQSOysR"></path>
      <path
          d="M265.95 154.68C265.95 158.39 263.06 161.4 259.5 161.4C255.94 161.4 253.05 158.39 253.05 154.68C253.05 150.97 255.94 147.96 259.5 147.96C263.06 147.96 265.95 150.97 265.95 154.68Z"
          id="b3VJHIq8Q8"></path>
    </defs>
    <g>
      <g>
        <use xlink:href="#e3RLvoflfL1" opacity="1" fill="#ffffff" class="shadow-lg" fill-opacity="1"></use>
      </g>
      <g>
        <use xlink:href="#e3RLvoflfL2" opacity="1" fill="#ffffff" fill-opacity="1"></use>
      </g>
      <g>
        <use xlink:href="#e7IUXBSURh" opacity="1" fill="#2a4365" class="animate-pulse" fill-opacity=".6"></use>
      </g>
      <g>
        <use xlink:href="#f1nvNLVapS" opacity="1" fill="#2a4365" fill-opacity=".8"></use>
      </g>
      <g>
        <use xlink:href="#a2oLNBRUjU" opacity="1" fill="#2a4365" class="animate-pulse" fill-opacity="1"></use>
      </g>
      <g>
        <use xlink:href="#e3FQSOysR" opacity="1" fill="#2a4365" fill-opacity=".6"></use>
      </g>
      <g>
        <use xlink:href="#b3VJHIq8Q8" opacity="1" fill="#548424" fill-opacity="1"></use>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgMoon'
}
</script>

<style scoped>

</style>